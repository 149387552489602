import React, { useState } from "react";

import "./styles/styles.css";
import Hamburger from "hamburger-react";

const Header = () => {
  const [isOpen, setOpen] = useState(false);

  function openMenu() {
    setOpen(!isOpen);
  }

  return (
    <header style={{ display: "flex", justifyContent: "center" }}>
      <span className="logo">EKTRN</span>
      {/* <Hamburger color="#EA638C" toggle={openMenu} toggled={isOpen} /> */}
    </header>
  );
};

export default Header;
