import React, { useEffect, useState } from "react";
import Header from "./components/Header/Header";
import { MDBAccordion, MDBAccordionItem } from "mdb-react-ui-kit";
import { Fade } from "react-awesome-reveal";

import "./styles/main/main.css";

import gallery01 from "./assets/images/gallery01.webp";
import ektrn03 from "./assets/images/ektrn03.webp";
import ektrn01 from "./assets/images/ektrn01.webp";
import ektrn02 from "./assets/images/ektrn02.webp";
import gallery02 from "./assets/images/gallery02.webp";
import gallery03 from "./assets/images/gallery03.webp";
import gallery04 from "./assets/images/gallery04.webp";
import gallery05 from "./assets/images/ektrn05.webp";
import gallery06 from "./assets/images/ektrn06.webp";
import gallery07 from "./assets/images/ektrn07.webp";
import gallery08 from "./assets/images/ektrn08.webp";
import love from "./assets/images/ektrn_love.webp";

type Particle = {
  id: number;
  x: number;
  y: number;
};

const App = () => {
  const [loveQuantity, setLoveQuantity] = useState(0);
  const [remainBurgers, setRemainBurgers] = useState(30);
  const [particles, setParticles] = useState<Particle[]>([]);
  const [imgSrc, setImgSrc] = useState("");

  const [shake, setShake] = useState(false);

  useEffect(() => {
    if (remainBurgers < 1) {
      setRemainBurgers(0);
    } else if (remainBurgers <= 10) {
      setImgSrc(ektrn02);
    } else if (remainBurgers <= 20) {
      setImgSrc(ektrn01);
    } else if (remainBurgers <= 30) {
      setImgSrc(ektrn03);
    }

    if (shake) {
      const timer = setTimeout(() => {
        setShake(false);
      }, 300);
      return () => clearTimeout(timer);
    }

    // Handling the particles cleanup
    const timers = particles.map((particle) => {
      return setTimeout(() => {
        setParticles((prev) => prev.filter((p) => p.id !== particle.id));
      }, 2000); // should match the animation duration
    });

    return () => timers.forEach((timer) => clearTimeout(timer));
  }, [shake, particles, remainBurgers]);

  const addParticle = () => {
    // Get the button's bounding box
    const buttonRect = document.querySelector(".btn")?.getBoundingClientRect();

    // If the button exists, use its position as the starting point for the heart particle
    if (buttonRect) {
      const newParticle = {
        id: Date.now(),
        x: buttonRect.left + buttonRect.width / 2, // center of the button
        y: buttonRect.top,
      };
      setParticles((prev) => [...prev, newParticle]);
    }
  };

  function addLove() {
    setLoveQuantity(loveQuantity + 10);
    setShake(true);
    addParticle(); // Add this line to generate particles when adding love
  }

  function feedKatya() {
    setShake(true);
    setRemainBurgers(remainBurgers - 1);
  }

  return (
    <>
      <Header />
      <main className="content">
        <Fade triggerOnce={true} direction="left" delay={100}>
          <h1>Ekaterina Kizimova</h1>
        </Fade>
        <Fade triggerOnce={true} direction="right" delay={200}>
          <h2>The Most Beautiful Girl On Earth</h2>
        </Fade>
        <Fade
          triggerOnce={true}
          direction="left"
          delay={300}
          style={{ marginTop: "clamp(16px,7.47648vw,64px)" }}
        >
          <span className="quote">—Your Lovely Boyfriend</span>
        </Fade>
        <Fade
          triggerOnce={true}
          direction="right"
          delay={400}
          style={{ marginTop: "clamp(16px,7.47648vw,64px)" }}
        >
          {/* <button className="button">Start The Journey</button> */}
        </Fade>
        <div className="gallery">
          <Fade triggerOnce direction="left" delay={500}>
            <img src={gallery01} alt="gallery-pic" className="gallery-pic" />
          </Fade>
          <Fade triggerOnce direction="left" delay={600}>
            <img src={gallery02} alt="gallery-pic" className="gallery-pic" />
          </Fade>{" "}
          <Fade triggerOnce direction="left" delay={700}>
            <img src={gallery03} alt="gallery-pic" className="gallery-pic" />
          </Fade>{" "}
          <Fade triggerOnce direction="left" delay={800}>
            <img src={gallery04} alt="gallery-pic" className="gallery-pic" />
          </Fade>
        </div>
        <section className="second-screen">
          <Fade triggerOnce={true} direction="left">
            <h3>Let’s See Enjoy The Perfection</h3>
          </Fade>
          <MDBAccordion alwaysOpen initialActive={1}>
            <MDBAccordionItem
              collapseId={1}
              headerTitle="The pretties girl"
              bodyClassName="custom-accordion"
            >
              <img className="ektrn-img" src={gallery05} alt="ekt"></img>
              <p className="article-paragraph">
                As you can see this is the prettiest girl on this planet. She is
                as beautiful as heaven and I love her so much, I can literally
                worship her beautifulness.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={2}
              headerTitle="The brightest star"
              bodyClassName="custom-accordion"
            >
              <img className="ektrn-img" src={gallery06} alt="ekt"></img>
              <p className="article-paragraph">
                Without a doubt, she possesses a charm that is unparalleled. Her
                allure is like the first rays of the sun breaking the night's
                spell. The way she carries herself, with grace and elegance,
                reminds me of a delicate dance of petals in the wind. Every
                moment with her feels like an ethereal dream, and my heart
                swells with gratitude for every second I get to admire her
                grace.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={3}
              headerTitle="The wildest dream"
              bodyClassName="custom-accordion"
            >
              <img className="ektrn-img" src={gallery07} alt="ekt"></img>
              <p className="article-paragraph">
                Truly, she is the embodiment of everything magical in the
                universe. With eyes that seem to capture the mysteries of the
                cosmos, she glows with a light that's reminiscent of the moon's
                silver sheen. In her laughter, I hear the symphony of stars, and
                in her presence, I feel the serenity of a still night. She's a
                mesmerizing force, and I find myself enchanted, every single
                day.
              </p>
            </MDBAccordionItem>
            <MDBAccordionItem
              collapseId={4}
              headerTitle="The sweetest melody"
              bodyClassName="custom-accordion"
            >
              <img className="ektrn-img" src={gallery08} alt="ekt"></img>
              <p className="article-paragraph">
                Beholding her is like witnessing a masterpiece come to life.
                Every facet of her being radiates a warmth and beauty that's
                reminiscent of the most captivating sunset. Just as the ocean
                holds endless depths and mysteries, there's so much to her
                that's waiting to be discovered. With every passing day, I find
                myself more captivated, more smitten, and truly blessed to know
                such a magnificent soul.
              </p>
            </MDBAccordionItem>
          </MDBAccordion>
        </section>
        <section className="third-screen">
          <h3 className="heading">Love Counter</h3>
          <img
            className={`love-img ${shake ? "shake-effect" : ""}`}
            src={love}
            alt="love"
          ></img>
          <span className="love-counter">{loveQuantity} Loves</span>
          {particles.map((particle) => (
            <span
              key={particle.id}
              className="particle"
              style={{ left: `${particle.x}px`, top: `${particle.y}px` }}
            >
              ❤️
            </span>
          ))}
          <button className="buzun" onClick={addLove}>
            Add More
          </button>
        </section>
        <section className="fourth-screen">
          <h3 className="heading">Feed Her</h3>
          <img
            className={`love-img-z ${shake ? "shake-effect-z" : ""}`}
            src={imgSrc}
            alt="love"
          ></img>
          <span className="love-counter">
            {remainBurgers} remaining burgers
          </span>
          <span className="hungry-text">I’m evil as hell, feed me!</span>
          <button
            className="buzun"
            onClick={feedKatya}
            style={{ marginBottom: "clamp(64px,29.90592vw,256px)" }}
          >
            🍔
          </button>
        </section>
      </main>
    </>
  );
};

export default App;
